$background-color: rgba(244, 244, 244, 1);
$dm-background-color: #000;
$title-color: rgba(64, 64, 64, 1);
$dm-title-color: rgba(198, 198, 198, 1);
$text-color: rgba(153, 153, 153, 1);
$dm-text-color: rgba(198, 198, 198, 1);
$forecast-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
$dm-forecast-shadow: 0 2px 4px 0 #000;
$forecast-border: solid 1px #f0f0f0;
$dm-forecast-border: solid 1px #333333;
$forecast-background: #FFF;
$dm-forecast-background: rgba(31, 31, 31, 1);
$forecast-main-color: $title-color;
$dm-forecast-main-color: $dm-title-color;