@import 'variables';

.Forecast {
  flex-basis: 50%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media(min-width: 421px) {
    justify-content: center;
  }
  @media(max-width: 420px) {
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 10px;
  }
  .ForecastIcon {
    flex-basis: 35px;
    img {
      display: block;
    }
  }
  .ForecastMain {
    color: $forecast-main-color;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0 15px 0 8px;
    @media(max-width: 420px) {
      padding-right: 0;
    }
    > span {
      display: block;
      font-size: 10px;
      line-height: 11px;
      margin-bottom: 4px;
      &:last-of-type {
        font-size: 28px;
        line-height: 21px;
        margin: 0;
      }
    }
    .unit {
      position: absolute;
      display: inline-block;
      font-size: 10px;
      line-height: 10px;
      margin: -1px 0 0 1px;
      text-transform: uppercase;
    }
  }
  .ForecastExtras {
    @media(max-width: 420px) {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
    .temperature {
      margin: 0 0 5px 0;
      @media(max-width: 420px) {
        margin: 0 15px 0 0;
      }
    }
    .icon {
      display: inline-block;
      width: 15px;
      text-align: center;
      margin-right: 5px;
      vertical-align: middle;
    }
  }
}

body.mobile {
  @media (prefers-color-scheme: dark) {
    .Forecast {
      .ForecastMain {
        color: $dm-forecast-main-color;
      }
      .ForecastExtras {
        .icon {
          svg path.umbrella {
            fill: rgba(198, 198, 198, 1);
          }
        }
      }
    }
  }
}