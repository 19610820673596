@import 'variables';

h1 {
  font-size: 13px;
  text-transform: uppercase;
  margin: 0 0 4px 8px;
  color: $title-color;
}
.forecast-container {
  display: flex;
  align-items: stretch;
  border-radius: 4px;
  box-shadow: $forecast-shadow;
  border: $forecast-border;
  background: $forecast-background;
  .Forecast {
    &:first-child {
      border-right: $forecast-border;
    }
  }
}

body.mobile {
  @media (prefers-color-scheme: dark) {
    h1 {
        color: $dm-title-color;
    }
    .forecast-container {
      box-shadow: $dm-forecast-shadow;
      background: $dm-forecast-background;
      border: $dm-forecast-border;
      .Forecast {
        &:first-child {
            border-right: $dm-forecast-border;
        }
      }
    }
  }
}