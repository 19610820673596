@import-normalize;
@import 'variables';

* {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

body {
  margin: 0;
  padding: 8px;
  font-size: 12px;
  color: $text-color;
  background: $background-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif;
  &.mobile {
    @media (prefers-color-scheme: dark) {
      color: $dm-text-color;
      background: $dm-background-color;
    }
  }
}